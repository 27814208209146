<template>
  <div class="personInfo-container">
    <div class="waitLoading" v-show="skeleton">
      <div class="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div v-show="!skeleton">
      <div class="head-title">
        <span>请完善个人信息</span>
      </div>
      <div class="infoMsg">
        <div class="prompt">
          <div class="code">
            <img class="img" src="@/assets/smartcat/weChat.jpg" alt="">
          </div>
          <div class="text">
            <span class="span-text">为了方便相应工作人员与您联系,</span> <br>
            <span class="span-text">请完善个人信息并扫描二维码添加客服</span>
          </div>
        </div>
        <div class="contact-way">
          <div class="contact-item" v-if="false">
            <input v-model="form.name" class="input" placeholder="账号*" />
          </div>
          <div class="contact-item">
            <input v-model="form.email" class="input" placeholder="请填写邮箱*" />
          </div>
          <div class="contact-item">
            <div class="phone">
              <a-select v-model="form.countryId" placeholder="请选择区号" class="rounded-select custom-select" show-search
                :filter-option="filterOption" style="padding-left:5px">
                <a-icon style="margin-top:-4px" slot="suffixIcon" type="caret-down" :style="{ fontSize: '16px' }" />
                <a-select-option v-for="item in country" :key="item.id" :label="item.name" :value="item.timezone">
                  {{ item.name }}&nbsp;+{{ item.timezone }}
                </a-select-option>
              </a-select>
              <div class="cut-off-rule"></div>
              <input v-model="form.phone" class="input inputs" placeholder="请输入手机号" />
            </div>
          </div>
          <div class="contact-item">
            <input v-model="form.code" class="input" placeholder="请输入验证码" />
            <button @click="sendCode()" class="code-btn"  :disabled="disabled">{{ btnTitle }}</button>
          </div>
          <div class="contact-item">
            <a-select v-model="form.country_id" placeholder="请选择国家" show-search :filter-option="filterOption"
              class="normalSel">
              <a-icon style="margin-top:-4px" slot="suffixIcon" type="caret-down" :style="{ fontSize: '16px' }" />
              <a-select-option v-for="item in country" :key="item.id" :label="item.name" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="contact-item">
            <button @click="submit" class="btn">{{ btnText }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {

  data () {
    return {
      redact: false,
      form: {
        username: '',
        name: '',
        phone: '',
        code: '',
        email: '',
        countryId: undefined, //区号
        country_id: undefined,

      },
      btnTitle: '获取验证码',
      btnText: '提交',
      disabled: false,
      id: '',
      country: [
        {
          label: '',
          value: ''
        }
      ],
      skeleton: true
    }
  },
  mounted () {

  },
  created () {
    this.getCountry()
    this.id = this.$store.state.id
    if (this.id) {
      this.btnText = '修改'
    }
    this.getInformation(this.id)
  },

  methods: {
    //区号
    getCountry () {
      this.$request.country().then(res => {
        if (res.ret) {
          this.country = res.data
        }
      })
    },
    getInformation (id) {
      this.$request.getInformation(id).then(res => {
        if (res.ret) {
          this.skeleton = false
          this.form = res.data
          this.form.countryId = res.data.country.timezone
          if (this.form.country_id == 0) {
            this.form.country_id = undefined
          }
        }
      })
    },
    filterOption (input, option) {
      return option.componentOptions.propsData.label.indexOf(input) > -1;
    },
    sendCode () {
      if (this.form.phone == '') {
        return this.$message.error('请输入手机号');
      } else if (this.form.countryId == undefined) {
        return this.$message.error('请选择区号');
      }
      let params = {
        receiver: this.form.countryId + this.form.phone
      }
      this.$request.register(params).then(res => {
        if (res.ret) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })
      var time = 60;
      var timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + '秒后重试';
          this.disabled = true;
          time--
        }
      }, 1000)
    },
    submit () {
      if (this.form.name == '') {
        return this.$message.error('请输入姓名');
      } else if (this.form.email == '') {
        return this.$message.error('请输入邮箱');
      } else if (this.form.code == '') {
        return this.$message.error('请输入验证码');
      } else if (this.form.countryId == undefined) {
        return this.$message.error('请选择区号');
      } else if (this.form.phone == '') {
        return this.$message.error('请输入手机号');
      } else if (this.form.country_id == undefined) {
        return this.$message.error('请输入国家');
      }
      this.form.username = this.form.name
      let params = {
        username: this.form.name,
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        code: this.form.code,
        country_id: this.form.country_id,
      }
      this.$request.subInformation(this.id, params).then(res => {
        if (res.ret) {
          this.$message.success(res.msg)
          this.$router.go(0)
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }

}
</script>
  
<style lang="scss" scoped>
/deep/.ant-select-selection__placeholder {
  margin-left: 5px;
  color: #757575;
}

/deep/.rounded-select .ant-select-selection {
  border: none !important;
  box-shadow: none;

}

/deep/.rounded-select .ant-select-selection--single {
  width: 125px;
  height: 36px;
  border-radius: 20px 0px 0 20px;
  background-color: #f0f0f0;
  padding-top: 2px;
  border-right: 0;

}

/deep/.normalSel .ant-select-selection {
  border: none !important;
  box-shadow: none;
}

/deep/.normalSel .ant-select-selection--single {
  width: 360px;
  height: 36px;
  border-radius: 20px;
  background-color: #f0f0f0;
  padding-top: 2px;
  border-right: 0;
  padding-left: 5px;
}

.personInfo-container {
  background-color: #fff;
  height: 906px;

  .waitLoading {
    height: 220PX;

    .loading,
    .loading>div {
      position: relative;
      box-sizing: border-box;
    }

    .loading {
      display: block;
      margin: auto;
      margin-top: 100px;
      font-size: 0;
      color: #000;
    }

    .loading.la-dark {
      color: #333;
    }

    .loading>div {
      display: inline-block;
      float: none;
      background-color: currentColor;
      border: 0 solid currentColor;
    }

    .loading {
      width: 16px;
      height: 16px;
    }

    .loading>div {
      position: absolute;
      top: 0;
      left: -100%;
      display: block;
      width: 16px;
      width: 100%;
      height: 16px;
      height: 100%;
      border-radius: 100%;
      opacity: 0.5;
      animation: ball-circus-position 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1),
        ball-circus-size 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1);
    }

    .loading>div:nth-child(1) {
      animation-delay: 0s, -0.5s;
    }

    .loading>div:nth-child(2) {
      animation-delay: -0.5s, -1s;
    }

    .loading>div:nth-child(3) {
      animation-delay: -1s, -1.5s;
    }

    .loading>div:nth-child(4) {
      animation-delay: -1.5s, -2s;
    }

    .loading>div:nth-child(5) {
      animation-delay: -2s, -2.5s;
    }

    @keyframes ball-circus-position {
      50% {
        left: 100%;
      }
    }

    @keyframes ball-circus-size {
      50% {
        transform: scale(0.3, 0.3);
      }
    }
  }

  .head-title {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 28px;
    margin-top: 20px;
    margin-left: 30px;
    color: rgba(0, 0, 0, 1);
  }

  .infoMsg {
    margin-left: 285px;

    .prompt {
      display: flex;

      .code {
        .img {
          width: 100px;
          height: 100px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        }
      }

      .text {
        margin-left: 20px;

        .span-text {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 22.4px;
          color: rgba(0, 0, 0, 1);
        }
      }
    }

    .contact-way {
      width: 360px;
      margin-top: 30px;

      .contact-item {
        margin-top: 25px;
        position: relative;

        .input {
          width: 360px;
          border-width: 0px;
          padding-left: 20px;
          height: 36px;
          border-radius: 18px;
          background-color: #f0f0f0;
          border-color: none;
          outline: none;
        }

        .code-btn {
          position: absolute;
          width: 110px;
          height: 36px;
          opacity: 1;
          border-radius: 0px 18px 18px 0px;
          background: rgba(255, 208, 0, 1);
          border: none;
          cursor: pointer;
          font-size: 14px;
          right: 0;
          bottom: 0;
        }
        .code-btn:hover{
            background-color: #000;
            color: #fff; 
          }
        .phone {
          display: flex;
          align-items: center;
          width: 360px;
          height: 36px;
          line-height: 36px;
          background-color: #f0f0f0;
          border-radius: 18px;

          .cut-off-rule {
            width: 2px;
            height: 24px;
            background: rgba(204, 204, 204, 1);
          }

        }

        .btn {
          margin-top: 10px;
          width: 360px;
          height: 36px;
          border-radius: 18px;
          opacity: 1;
          border-width: 0;
          background-color: #ffd000;
          cursor: pointer;
          color: #000;
        }

        .btn:hover {
          border-color: #000 !important;
          color: #fff;
          background: #000;
        }
      }

    }
  }


}

</style>
<style>
    ul> .ant-select-dropdown-menu-item-active{
  background: #ffd000 !important;
}
</style>
