<template>
    <div>审核信息</div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>